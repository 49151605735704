<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-card-title>
      <v-app-bar
        flat
        class="white pa-0"
        elevate-on-scroll
        dense
      >
        <v-toolbar-title>{{ $t('export.title') }}</v-toolbar-title>
        <v-spacer />
      </v-app-bar>
    </v-card-title>
    <v-card-text />
  </v-card>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
export default {

}
</script>

<style>

</style>
